import { useForm } from '@tanstack/react-form';
import {
  defaultPreferredCommunicationMethods,
  defaultSpecialNeeds,
} from '../defaults';
import { PatientInfoDTO } from '@aster/app/core/shared/dtos/patient';

export type CommunicationPreferencesFields = Pick<
  PatientInfoDTO,
  'preferredCommunicationMethods' | 'specialNeeds'
>;

export const useCommunicationPreferencesForm = ({
  defaultValues,
  onSubmit,
}: {
  defaultValues: PatientInfoDTO | undefined;
  onSubmit: (value: CommunicationPreferencesFields) => void;
}) => {
  const communicationPreferencesForm = useForm({
    defaultValues: {
      preferredCommunicationMethods: defaultPreferredCommunicationMethods.map(
        (method) => ({
          ...method,
          checked:
            defaultValues?.preferredCommunicationMethods?.find(
              (item) => item.value === method.value
            )?.checked ?? false,
        })
      ),
      specialNeeds: defaultSpecialNeeds.map((need) => ({
        ...need,
        checked:
          defaultValues?.specialNeeds?.find((item) => item.value === need.value)
            ?.checked ?? false,
      })),
    } as CommunicationPreferencesFields,
    onSubmit: async ({ value }) => {
      onSubmit(value);
    },
  });

  return communicationPreferencesForm;
};
