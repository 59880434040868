import { Button } from '@aster/client/ui/Button/Button';
import { Label } from '@aster/client/ui/Label/Label';
import { UseMutationResult } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { useParams } from 'react-router';
import {
  CreatePatientDTO,
  PatientInfoDTO,
} from '@aster/app/core/shared/dtos/patient';
import { ReactFormExtendedApi } from '@tanstack/react-form';

type ProfileSaveButtonProps<T> = {
  lastSavedString: string;
  updatePatientMutation: UseMutationResult<
    AxiosResponse<any, any>,
    Error,
    CreatePatientDTO | Partial<PatientInfoDTO>,
    unknown
  >;
  form: ReactFormExtendedApi<T, any, any, any, any, any, any, any, any, any>;
};

export function ProfileSaveButton<T>({
  lastSavedString,
  updatePatientMutation,
  form,
}: ProfileSaveButtonProps<T>) {
  const { patient } = useParams<{ patient: string }>();
  return (
    patient && (
      <form.Subscribe
        selector={(state) => [state.canSubmit, state.isSubmitting]}
        children={([canSubmit, isSubmitting]) => (
          <div className="flex items-center justify-end bg-white z-10 p-4 absolute bottom-0 right-0 gap-4 w-full">
            <Label>{lastSavedString}</Label>
            <Button
              isLoading={updatePatientMutation.status === 'pending'}
              className="rounded-[8px]"
              disabled={
                updatePatientMutation.status === 'pending' ||
                !canSubmit ||
                isSubmitting
              }
              type="submit"
              onClick={() => form.handleSubmit()}
            >
              Save
            </Button>
          </div>
        )}
      ></form.Subscribe>
    )
  );
}
