import { CircularProgress } from '@mui/material';
import { Input } from '@aster/client/ui/Input/Input';
import { FormControl } from '@aster/client/ui/FormControl/FormControl';
import { Label } from '@aster/client/ui/Label/Label';
import { FormError } from '@aster/client/ui/FormControl/FormError';
import { Checkbox } from '@aster/client/ui/Checkbox/Checkbox';
import { useInsuranceInfoForm } from '../hooks/useInsuranceInfoForm';
import { isPatientReadonly } from '../utils/is-patient-readonly';
import { useDebouncedCallback } from 'use-debounce';
import { Profile, useAuth } from '../../../authentication/AuthProvider';
import { useUserUsagePreferences } from '@aster/shared/shared/client/utils';
import { LAST_SAVED_INSURANCE_INFORMATION } from '../constants';
import dayjs from 'dayjs';
import { ProfileSaveButton } from '../profileTabs/components/ProfileSaveButton';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import timeSinceLastSaved from '../utils/timeSinceLastSaved';
import { useProfileTabStore } from '../profileTabs/ProfileTab.store';
import { useShallow } from 'zustand/react/shallow';
import { PatientInfoDTO } from '@aster/app/core/shared/dtos/patient';
import { useStore } from '@tanstack/react-form';
import { Button } from '@aster/client/ui/Button/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrash } from '@fortawesome/pro-light-svg-icons';
import { cn } from '@aster/client/utils/cn';
import { Typography } from '@aster/client/ui/Typography/Typography';

const Insurance = forwardRef(
  (
    {
      updatePatientMutation,
      patientInfo,
      isPatientLoading,
    }: {
      patientInfo: PatientInfoDTO | undefined;
      updatePatientMutation: any;
      isPatientLoading: boolean;
    },
    ref
  ) => {
    const { setFormUnsavedStatus } = useProfileTabStore(
      useShallow((state) => ({
        setFormUnsavedStatus: state.setFormUnsavedStatus,
      }))
    );
    const [debounceValue, setDebounceValue] = useState<number>(2000);
    useImperativeHandle(ref, () => ({
      submit: async () => {
        setDebounceValue(0);
        await insuranceInfoForm.handleSubmit();
      },
    }));

    const { profile } = useAuth();
    const { storePreference, readPreference } = useUserUsagePreferences(
      profile as Profile
    );

    const lastSaved = readPreference<string>(LAST_SAVED_INSURANCE_INFORMATION);
    const lastSavedString = lastSaved ? timeSinceLastSaved(lastSaved) : '';

    const insuranceInfoForm = useInsuranceInfoForm({
      defaultValues: patientInfo as PatientInfoDTO,
      onSubmit: async (value) => {
        setFormUnsavedStatus('insurance', true);
        await save(value);
      },
    });

    // Save function debounced for more efficient API usage
    const save = useDebouncedCallback(
      async (value) => {
        await updatePatientMutation.mutateAsync(value);
        storePreference(
          LAST_SAVED_INSURANCE_INFORMATION,
          dayjs().toISOString()
        );
        setDebounceValue(1000);
        setFormUnsavedStatus('insurance', false);
      },
      debounceValue,
      { trailing: true }
    );

    const values = useStore(insuranceInfoForm.store, (state) => state.values);

    useEffect(() => {
      void insuranceInfoForm.handleSubmit();
    }, [insuranceInfoForm, values]);

    useEffect(() => {
      if (updatePatientMutation.isPending) {
        setFormUnsavedStatus('insurance', true);
      }
    }, [setFormUnsavedStatus, updatePatientMutation.isPending]);

    const readonly = isPatientReadonly(patientInfo);

    return (
      <>
        <ProfileSaveButton
          lastSavedString={lastSavedString}
          updatePatientMutation={updatePatientMutation}
          form={insuranceInfoForm}
        />
        <div className="pb-20 w-full container">
          {isPatientLoading ? (
            <CircularProgress />
          ) : (
            <form
              onSubmit={(evt) => {
                evt.preventDefault();
                evt.stopPropagation();
                void insuranceInfoForm.handleSubmit();
              }}
            >
              <div className="flex h-fit">
                <div className="flex flex-col gap-y-5 w-full">
                  <Typography variant="h5" className="font-semibold mb-5">
                    Insurance
                  </Typography>
                  <insuranceInfoForm.Field
                    name="cashOnly"
                    children={(field) => (
                      <FormControl className="relative flex flex-row gap-2">
                        <Checkbox
                          id={field.name}
                          disabled={readonly}
                          checked={field.state.value}
                          onCheckedChange={() => {
                            field.handleChange(!field.state.value);
                          }}
                        />
                        <Label
                          htmlFor={field.name}
                          className="font-normal text-aster-secondary cursor-pointer"
                        >
                          Cash pay only
                        </Label>
                      </FormControl>
                    )}
                  />

                  <div className="w-full">
                    <insuranceInfoForm.Field
                      name="insurances"
                      mode="array"
                      children={(field) => (
                        <div className="flex flex-col gap-5 w-full">
                          <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
                            {field.state.value.map((_, index) => (
                              <div
                                key={index}
                                className={cn(
                                  'flex flex-col gap-y-5 border-gray-300 p-6 border rounded-[8px]'
                                )}
                              >
                                <div className="flex items-center justify-between mb-2">
                                  <Typography
                                    variant="h5"
                                    className={cn('text-black')}
                                  >
                                    {index === 0
                                      ? 'Primary Insurance'
                                      : `Insurance ${index + 1}`}
                                  </Typography>
                                  {field.state.value.length > 1 &&
                                    !readonly && (
                                      <Button
                                        type="button"
                                        size="default"
                                        variant="secondary"
                                        onClick={async () => {
                                          field.removeValue(index);
                                        }}
                                      >
                                        <FontAwesomeIcon
                                          icon={faTrash}
                                          className="mr-2"
                                        />
                                        Remove
                                      </Button>
                                    )}
                                </div>

                                <insuranceInfoForm.Field
                                  name={`insurances[${index}].insuranceProvider`}
                                  children={(providerField) => (
                                    <FormControl>
                                      <Label>Insurance Provider</Label>
                                      <Input
                                        className="bg-white"
                                        value={providerField.state.value}
                                        onChange={(evt) => {
                                          providerField.handleChange(
                                            evt.target.value
                                          );
                                        }}
                                        onBlur={() => {
                                          providerField.handleBlur();
                                        }}
                                        disabled={readonly}
                                        errorMessageId={`err-${providerField.name}`}
                                      />
                                      <FormError
                                        id={`err-${providerField.name}`}
                                      >
                                        {providerField.state.meta.errors?.join(
                                          '\r'
                                        )}
                                      </FormError>
                                    </FormControl>
                                  )}
                                />

                                <insuranceInfoForm.Field
                                  name={`insurances[${index}].insuranceNumber`}
                                  children={(numberField) => (
                                    <FormControl>
                                      <Label>Insurance Number</Label>
                                      <Input
                                        className="bg-white"
                                        value={numberField.state.value}
                                        onChange={(evt) => {
                                          numberField.handleChange(
                                            evt.target.value
                                          );
                                        }}
                                        onBlur={() => {
                                          numberField.handleBlur();
                                        }}
                                        disabled={readonly}
                                        errorMessageId={`err-${numberField.name}`}
                                      />
                                      <FormError id={`err-${numberField.name}`}>
                                        {numberField.state.meta.errors?.join(
                                          '\r'
                                        )}
                                      </FormError>
                                    </FormControl>
                                  )}
                                />

                                <insuranceInfoForm.Field
                                  name={`insurances[${index}].insuranceGroupId`}
                                  children={(groupField) => (
                                    <FormControl>
                                      <Label>Insurance Group Id</Label>
                                      <Input
                                        className="bg-white"
                                        value={groupField.state.value}
                                        onChange={(evt) => {
                                          groupField.handleChange(
                                            evt.target.value
                                          );
                                        }}
                                        onBlur={() => {
                                          groupField.handleBlur();
                                        }}
                                        disabled={readonly}
                                        errorMessageId={`err-${groupField.name}`}
                                      />
                                      <FormError id={`err-${groupField.name}`}>
                                        {groupField.state.meta.errors?.join(
                                          '\r'
                                        )}
                                      </FormError>
                                    </FormControl>
                                  )}
                                />

                                <insuranceInfoForm.Field
                                  name={`insurances[${index}].useForBaby`}
                                  children={(useForBabyField) => (
                                    <FormControl className="relative flex flex-row gap-2">
                                      <Checkbox
                                        id={useForBabyField.name}
                                        className="bg-white"
                                        disabled={readonly}
                                        checked={useForBabyField.state.value}
                                        onCheckedChange={() => {
                                          useForBabyField.handleChange(
                                            !useForBabyField.state.value
                                          );
                                          // Form submission handled by useStore
                                        }}
                                      />
                                      <Label
                                        className="font-normal text-aster-secondary cursor-pointer"
                                        htmlFor={useForBabyField.name}
                                      >
                                        Apply this insurance to newborns
                                      </Label>
                                    </FormControl>
                                  )}
                                />
                              </div>
                            ))}
                          </div>
                          {!readonly && (
                            <Button
                              type="button"
                              variant="secondary"
                              size="default"
                              className="mt-2 w-fit"
                              onClick={() => {
                                field.pushValue({
                                  insuranceProvider: '',
                                  insuranceNumber: '',
                                  insuranceGroupId: '',
                                  useForBaby: false,
                                });
                                // Form submission handled by useStore
                              }}
                            >
                              <FontAwesomeIcon icon={faPlus} className="mr-2" />
                              Add Insurance
                            </Button>
                          )}
                        </div>
                      )}
                    />
                  </div>
                </div>
              </div>
            </form>
          )}
        </div>
      </>
    );
  }
);

export default Insurance;
