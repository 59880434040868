import Typography from '../../../components/Typography';
import { CircularProgress } from '@mui/material';
import {
  RadioGroup,
  RadioIndicator,
  RadioItem,
} from '@aster/client/ui/Radio/Radio';
import { Label } from '@aster/client/ui/Label/Label';
import { FormControl } from '@aster/client/ui/FormControl/FormControl';
import {
  GynHistoryFields,
  useGynecologicHistoryInfoForm,
} from '../hooks/useGynecologicHistoryInfoForm';
import { Input } from '@aster/client/ui/Input/Input';
import { Checkbox } from '@aster/client/ui/Checkbox/Checkbox';
import {
  defaultPreviousInfectionsDiseasesOrSTI,
  defaultProblemsWithPeriods,
  defaultSexualActivityType,
  defaultSexualProtectionType,
} from '../defaults';
import { InputMask } from '@react-input/mask';
import dayjs from 'dayjs';
import { isPatientReadonly } from '../utils/is-patient-readonly';
import { useDebouncedCallback } from 'use-debounce';
import { Profile, useAuth } from '../../../authentication/AuthProvider';
import { useUserUsagePreferences } from '@aster/shared/shared/client/utils';
import { LAST_SAVED_GYNECOLOGIC_HISTORY } from '../constants';
import { ProfileSaveButton } from '../profileTabs/components/ProfileSaveButton';
import { forwardRef, useImperativeHandle, useState } from 'react';
import timeSinceLastSaved from '../utils/timeSinceLastSaved';
import { useProfileTabStore } from '../profileTabs/ProfileTab.store';
import { useShallow } from 'zustand/react/shallow';
import {
  MultipleChoiceQuestion,
  PatientInfoDTO,
} from '@aster/app/core/shared/dtos/patient';
import { useStore } from '@tanstack/react-form';

const GynecologicHistory = forwardRef(
  (
    {
      updatePatientMutation,
      patientInfo,
      isPatientLoading,
    }: {
      patientInfo: PatientInfoDTO | undefined;
      updatePatientMutation: any;
      isPatientLoading: boolean;
    },
    ref
  ) => {
    const { setFormUnsavedStatus } = useProfileTabStore(
      useShallow((state) => ({
        setFormUnsavedStatus: state.setFormUnsavedStatus,
      }))
    );
    const [debounceValue, setDebounceValue] = useState<number>(2000);
    useImperativeHandle(ref, () => ({
      submit: async () => {
        setDebounceValue(0);
        await gynecologicHistoryForm.handleSubmit();
      },
    }));

    const [averageLengthOfPeriodsUnsure, setAverageLengthOfPeriodsUnsure] =
      useState(() => patientInfo?.averagePeriodLength === null);
    const [averageCycleLengthUnsure, setAverageCycleLengthUnsure] = useState(
      () => patientInfo?.averagePeriodCycleLength === null
    );

    const { profile } = useAuth();
    const { storePreference, readPreference } = useUserUsagePreferences(
      profile as Profile
    );

    const lastSaved = readPreference<string>(LAST_SAVED_GYNECOLOGIC_HISTORY);
    const lastSavedString = lastSaved ? timeSinceLastSaved(lastSaved) : '';

    const gynecologicHistoryForm = useGynecologicHistoryInfoForm({
      defaultValues: patientInfo as PatientInfoDTO,
      onSubmit: (value) => {
        return save(value);
      },
    });

    useStore(gynecologicHistoryForm.store, ({ isDirty }) => {
      setFormUnsavedStatus('gynecologic-history', isDirty);
    });

    const save = useDebouncedCallback(
      async (value: Partial<GynHistoryFields>) => {
        await updatePatientMutation.mutateAsync(value);
        storePreference(LAST_SAVED_GYNECOLOGIC_HISTORY, dayjs().toISOString());
        setDebounceValue(2000);
        gynecologicHistoryForm.reset(gynecologicHistoryForm.state.values, {
          keepDefaultValues: true,
        });
      },
      debounceValue
    );

    const readonly = isPatientReadonly(patientInfo);

    return (
      <>
        <ProfileSaveButton
          lastSavedString={lastSavedString}
          updatePatientMutation={updatePatientMutation}
          form={gynecologicHistoryForm}
        />
        <div className="pb-20 w-full container">
          {isPatientLoading ? (
            <CircularProgress />
          ) : (
            <form
              onSubmit={(evt) => {
                evt.preventDefault();
                evt.stopPropagation();
                void gynecologicHistoryForm.handleSubmit();
              }}
            >
              <div className="flex h-fit">
                <div className="flex flex-col gap-y-5 w-full sm:w-auto">
                  <Typography
                    text="Gynecologic History"
                    variant="h5"
                    customClass="font-semibold"
                  ></Typography>
                  <gynecologicHistoryForm.Field
                    name="hasPeriods"
                    children={(field) => {
                      return (
                        <FormControl className="relative">
                          <Label className="mb-2 cursor-pointer">
                            Have you had a period?
                          </Label>
                          <RadioGroup
                            className="gap-4"
                            value={
                              field.state.value === true
                                ? 'yes'
                                : field.state.value === false
                                ? 'Never had a period'
                                : undefined
                            }
                            onValueChange={(value) => {
                              const hasPeriod = value === 'yes';
                              field.handleChange(hasPeriod);
                              if (!hasPeriod) {
                                field.form.state.values.arePeriodsRegular =
                                  null;
                                field.form.state.values.averagePeriodLength =
                                  null;
                                field.form.state.values.averagePeriodCycleLength =
                                  null;
                                field.form.state.values.cycleUnknown = false;
                                field.form.state.values.periodUnknown = false;
                              }
                              void gynecologicHistoryForm.handleSubmit();
                            }}
                          >
                            <div className="flex items-center gap-2">
                              <RadioItem
                                id={`${field.name}-yes`}
                                value="yes"
                                disabled={readonly}
                              >
                                <RadioIndicator />
                              </RadioItem>
                              <Label
                                htmlFor={`${field.name}-yes`}
                                className="m-0 font-normal text-aster-secondary cursor-pointer"
                              >
                                Yes
                              </Label>
                            </div>
                            <div className="flex items-center gap-2">
                              <RadioItem
                                id={`${field.name}-no`}
                                value="Never had a period"
                                disabled={readonly}
                              >
                                <RadioIndicator />
                              </RadioItem>
                              <Label
                                htmlFor={`${field.name}-no`}
                                className="m-0 font-normal text-aster-secondary cursor-pointer"
                              >
                                Never had a period
                              </Label>
                            </div>
                          </RadioGroup>
                        </FormControl>
                      );
                    }}
                  />
                  {gynecologicHistoryForm.state.values.hasPeriods && (
                    <>
                      <gynecologicHistoryForm.Field
                        name="ageOfFirstPeriod"
                        children={(field) => (
                          <FormControl className="relative">
                            <Label>Age of first period</Label>
                            <Input
                              className="max-w-[250px]"
                              placeholder="Age"
                              value={
                                field.state.value === 0
                                  ? ''
                                  : (field.state.value as number)
                              }
                              type="number"
                              onChange={(evt) => {
                                const value = Number(evt.target.value);
                                if (value > -1 && Number.isInteger(value)) {
                                  field.handleChange(Number(evt.target.value));
                                }
                              }}
                              onBlur={(evt) => {
                                if (field.state.meta.isDirty) {
                                  void gynecologicHistoryForm.handleSubmit();
                                }
                              }}
                              disabled={readonly}
                              errorMessageId={`err-${field.name}`}
                            />
                          </FormControl>
                        )}
                      />
                      <gynecologicHistoryForm.Field
                        name="arePeriodsRegular"
                        children={(field) => (
                          <FormControl className="relative">
                            <Label className="mb-2">
                              Are your periods regular?
                            </Label>
                            <RadioGroup
                              className="gap-4"
                              value={
                                field.state.value === true
                                  ? 'yes'
                                  : field.state.value === false
                                  ? 'no'
                                  : undefined
                              }
                              onValueChange={(value) => {
                                const arePeriodsRegular = value === 'yes';
                                field.handleChange(arePeriodsRegular);
                                void gynecologicHistoryForm.handleSubmit();
                              }}
                            >
                              <div className="flex items-center gap-2">
                                <RadioItem
                                  id={`${field.name}-yes`}
                                  value="yes"
                                  disabled={readonly}
                                >
                                  <RadioIndicator />
                                </RadioItem>
                                <Label
                                  htmlFor={`${field.name}-yes`}
                                  className="m-0 font-normal text-aster-secondary cursor-pointer"
                                >
                                  Yes
                                </Label>
                              </div>
                              <div className="flex items-center gap-2">
                                <RadioItem
                                  id={`${field.name}-no`}
                                  value="no"
                                  disabled={readonly}
                                >
                                  <RadioIndicator />
                                </RadioItem>
                                <Label
                                  htmlFor={`${field.name}-no`}
                                  className="m-0 font-normal text-aster-secondary cursor-pointer"
                                >
                                  No
                                </Label>
                              </div>
                            </RadioGroup>
                          </FormControl>
                        )}
                      />
                      <div className="flex flex-col">
                        <Label className="mb-2">
                          Average length of periods (Days)
                        </Label>
                        <div className="flex items-center gap-4">
                          <gynecologicHistoryForm.Field
                            name="averagePeriodLength"
                            children={(field) => (
                              <FormControl className="relative">
                                <Input
                                  placeholder="Days"
                                  value={
                                    field.state.value === 0
                                      ? ''
                                      : (field.state.value as number)
                                  }
                                  type="number"
                                  onChange={(evt) => {
                                    const value = Number(evt.target.value);
                                    if (value > -1 && Number.isInteger(value)) {
                                      field.handleChange(
                                        Number(evt.target.value)
                                      );
                                    }
                                  }}
                                  onBlur={(evt) => {
                                    if (field.state.meta.isDirty) {
                                      void gynecologicHistoryForm.handleSubmit();
                                    }
                                  }}
                                  disabled={
                                    readonly || averageLengthOfPeriodsUnsure
                                  }
                                  errorMessageId={`err-${field.name}`}
                                />
                              </FormControl>
                            )}
                          />

                          <FormControl className="relative flex flex-row items-center gap-2">
                            <Checkbox
                              id={`averagePeriodLength-unsure`}
                              checked={averageLengthOfPeriodsUnsure}
                              onCheckedChange={(value) => {
                                setAverageLengthOfPeriodsUnsure(
                                  value as boolean
                                );
                              }}
                              disabled={readonly}
                            />
                            <Label
                              htmlFor={`averagePeriodLength-unsure`}
                              className="m-0 font-normal text-aster-secondary cursor-pointer"
                            >
                              Unsure
                            </Label>
                          </FormControl>
                        </div>
                      </div>
                      <div className="flex flex-col">
                        <Label className="mb-2">
                          Average cycle length (every x days)
                        </Label>
                        <div className="flex items-center gap-4">
                          <gynecologicHistoryForm.Field
                            name="averagePeriodCycleLength"
                            children={(field) => (
                              <FormControl>
                                <Input
                                  placeholder="Days"
                                  value={
                                    field.state.value === 0
                                      ? ''
                                      : (field.state.value as number)
                                  }
                                  type="number"
                                  onChange={(evt) => {
                                    const value = Number(evt.target.value);
                                    if (value > -1 && Number.isInteger(value)) {
                                      field.handleChange(
                                        Number(evt.target.value)
                                      );
                                    }
                                  }}
                                  onBlur={(evt) => {
                                    if (field.state.meta.isDirty) {
                                      void gynecologicHistoryForm.handleSubmit();
                                    }
                                  }}
                                  disabled={
                                    readonly || averageCycleLengthUnsure
                                  }
                                  errorMessageId={`err-${field.name}`}
                                />
                              </FormControl>
                            )}
                          />
                          <FormControl className="relative flex flex-row items-center gap-2">
                            <Checkbox
                              id={`averagePeriodCycleLength-unsure`}
                              checked={averageCycleLengthUnsure}
                              onCheckedChange={(value) => {
                                setAverageCycleLengthUnsure(value as boolean);
                              }}
                              disabled={readonly}
                            />
                            <Label
                              htmlFor={`averagePeriodCycleLength-unsure`}
                              className="m-0 font-normal text-aster-secondary cursor-pointer"
                            >
                              Unsure
                            </Label>
                          </FormControl>
                        </div>
                      </div>
                      <gynecologicHistoryForm.Field
                        name="problemsWithPeriods"
                        children={(field) => (
                          <FormControl className="relative">
                            <Label className="mb-2">
                              Problems with periods
                            </Label>
                            <div className="flex flex-col flex-wrap gap-2 max-h-[200px]">
                              {defaultProblemsWithPeriods.map((problem) => (
                                <div key={problem.value} className="flex gap-2">
                                  <Checkbox
                                    id={`${field.name}-${problem.value}`}
                                    key={problem.value}
                                    checked={
                                      field.state.value?.find(
                                        (item: any) =>
                                          item.value === problem.value
                                      )?.checked
                                    }
                                    onCheckedChange={(value) => {
                                      if (value === 'indeterminate') return;
                                      const updatedItems =
                                        field.state.value?.map(
                                          (item: MultipleChoiceQuestion) =>
                                            item.value === problem.value
                                              ? { ...item, checked: value }
                                              : item
                                        );

                                      if (updatedItems) {
                                        field.handleChange(updatedItems);
                                        void gynecologicHistoryForm.handleSubmit();
                                      }
                                    }}
                                    disabled={readonly}
                                  />
                                  <Label
                                    htmlFor={`${field.name}-${problem.value}`}
                                    className="font-normal text-aster-secondary cursor-pointer"
                                  >
                                    {problem.text}
                                  </Label>
                                </div>
                              ))}
                            </div>
                          </FormControl>
                        )}
                      />
                      <gynecologicHistoryForm.Field
                        name="problemsWithPeriodsDetails"
                        children={(field) => (
                          <FormControl className="relative">
                            <Input
                              placeholder="Other details"
                              value={field.state.value as string}
                              onChange={(evt) => {
                                field.handleChange(evt.target.value);
                              }}
                              onBlur={(evt) => {
                                if (field.state.meta.isDirty) {
                                  void gynecologicHistoryForm.handleSubmit();
                                }
                              }}
                              disabled={readonly}
                            />
                          </FormControl>
                        )}
                      />
                    </>
                  )}
                  <gynecologicHistoryForm.Field
                    name="sexuallyActive"
                    children={(field) => (
                      <FormControl className="relative">
                        <Label className="mb-2">
                          Are you currently sexually active?
                        </Label>
                        <RadioGroup
                          className="gap-4"
                          value={
                            field.state.value as
                              | 'yes'
                              | 'no'
                              | 'never'
                              | undefined
                          }
                          onValueChange={(value) => {
                            field.handleChange(value as 'yes' | 'no' | 'never');
                            void gynecologicHistoryForm.handleSubmit();
                          }}
                        >
                          <div className="flex items-center gap-2">
                            <RadioItem
                              id={`${field.name}-yes`}
                              value="yes"
                              disabled={readonly}
                            >
                              <RadioIndicator />
                            </RadioItem>
                            <Label
                              htmlFor={`${field.name}-yes`}
                              className="m-0 font-normal text-aster-secondary cursor-pointer"
                            >
                              Yes
                            </Label>
                          </div>
                          <div className="flex items-center gap-2">
                            <RadioItem
                              id={`${field.name}-no`}
                              value="no"
                              disabled={readonly}
                            >
                              <RadioIndicator />
                            </RadioItem>
                            <Label
                              htmlFor={`${field.name}-no`}
                              className="m-0 font-normal text-aster-secondary cursor-pointer"
                            >
                              No
                            </Label>
                          </div>
                          <div className="flex items-center gap-2">
                            <RadioItem
                              id={`${field.name}-never`}
                              value="never"
                              disabled={readonly}
                            >
                              <RadioIndicator />
                            </RadioItem>
                            <Label
                              htmlFor={`${field.name}-never`}
                              className="m-0 font-normal text-aster-secondary cursor-pointer"
                            >
                              Never been
                            </Label>
                          </div>
                        </RadioGroup>
                      </FormControl>
                    )}
                  />
                  {gynecologicHistoryForm.state.values.sexuallyActive ===
                    'yes' && (
                    <>
                      <gynecologicHistoryForm.Field
                        name="sexualActivityType"
                        children={(field) => (
                          <FormControl className="relative">
                            <Label className="mb-2">
                              What types of sexual activity do you engage in?
                            </Label>
                            <div className="flex flex-col flex-wrap gap-2 max-h-[200px]">
                              {defaultSexualActivityType.map((problem) => (
                                <div key={problem.value} className="flex gap-2">
                                  <Checkbox
                                    id={`${field.name}-${problem.value}`}
                                    key={problem.value}
                                    checked={
                                      field.state.value?.find(
                                        (item: MultipleChoiceQuestion) =>
                                          item.value === problem.value
                                      )?.checked
                                    }
                                    onCheckedChange={(value) => {
                                      if (value === 'indeterminate') return;
                                      const updatedItems =
                                        field.state.value?.map(
                                          (item: MultipleChoiceQuestion) =>
                                            item.value === problem.value
                                              ? { ...item, checked: value }
                                              : item
                                        );

                                      if (updatedItems) {
                                        field.handleChange(updatedItems);
                                        void gynecologicHistoryForm.handleSubmit();
                                      }
                                    }}
                                    disabled={readonly}
                                  />
                                  <Label
                                    className="font-normal text-aster-secondary cursor-pointer"
                                    htmlFor={`${field.name}-${problem.value}`}
                                  >
                                    {problem.text}
                                  </Label>
                                </div>
                              ))}
                            </div>
                          </FormControl>
                        )}
                      />
                      <gynecologicHistoryForm.Field
                        name="sexualProtectionType"
                        children={(field) => (
                          <FormControl className="relative">
                            <Label className="mb-2">
                              Do you currently use any contraceptives and/or
                              protection?
                            </Label>
                            <div className="flex flex-col flex-wrap gap-2 max-h-[200px]">
                              {defaultSexualProtectionType.map((problem) => (
                                <div key={problem.value} className="flex gap-2">
                                  <Checkbox
                                    id={`${field.name}-${problem.value}`}
                                    key={problem.value}
                                    checked={
                                      field.state.value?.find(
                                        (item: MultipleChoiceQuestion) =>
                                          item.value === problem.value
                                      )?.checked
                                    }
                                    onCheckedChange={(value) => {
                                      if (value === 'indeterminate') return;
                                      const updatedItems =
                                        field.state.value?.map(
                                          (item: MultipleChoiceQuestion) =>
                                            item.value === problem.value
                                              ? { ...item, checked: value }
                                              : item
                                        );

                                      if (updatedItems) {
                                        field.handleChange(updatedItems);
                                        void gynecologicHistoryForm.handleSubmit();
                                      }
                                    }}
                                    disabled={readonly}
                                  />
                                  <Label
                                    className="font-normal text-aster-secondary cursor-pointer"
                                    htmlFor={`${field.name}-${problem.value}`}
                                  >
                                    {problem.text}
                                  </Label>
                                </div>
                              ))}
                            </div>
                          </FormControl>
                        )}
                      />
                      <gynecologicHistoryForm.Field
                        name="sexualProtectionTypeDetails"
                        children={(field) => (
                          <FormControl className="relative">
                            <Input
                              placeholder="Other details"
                              value={field.state.value as string}
                              onChange={(evt) => {
                                field.handleChange(evt.target.value);
                              }}
                              onBlur={(evt) => {
                                if (field.state.meta.isDirty) {
                                  void gynecologicHistoryForm.handleSubmit();
                                }
                              }}
                              disabled={readonly}
                            />
                          </FormControl>
                        )}
                      />
                      <gynecologicHistoryForm.Field
                        name="painDuringSex"
                        children={(field) => (
                          <FormControl className="relative">
                            <Label className="mb-2">
                              Do you experience or have you experienced pain
                              during sex?
                            </Label>
                            <RadioGroup
                              className="gap-4"
                              onValueChange={(value) => {
                                const hasPain = value === 'yes';
                                field.handleChange(hasPain);
                                void gynecologicHistoryForm.handleSubmit();
                              }}
                              value={
                                field.state.value === true
                                  ? 'yes'
                                  : field.state.value === false
                                  ? 'no'
                                  : undefined
                              }
                            >
                              <div className="flex items-center gap-2">
                                <RadioItem
                                  id={`${field.name}-yes`}
                                  value="yes"
                                  disabled={readonly}
                                >
                                  <RadioIndicator />
                                </RadioItem>
                                <Label
                                  htmlFor={`${field.name}-yes`}
                                  className="m-0 font-normal text-aster-secondary cursor-pointer"
                                >
                                  Yes
                                </Label>
                              </div>
                              <div className="flex items-center gap-2">
                                <RadioItem
                                  id={`${field.name}-no`}
                                  value="no"
                                  disabled={readonly}
                                >
                                  <RadioIndicator />
                                </RadioItem>
                                <Label
                                  htmlFor={`${field.name}-no`}
                                  className="m-0 font-normal text-aster-secondary cursor-pointer"
                                >
                                  No
                                </Label>
                              </div>
                            </RadioGroup>
                          </FormControl>
                        )}
                      />
                      {gynecologicHistoryForm.state.values.painDuringSex ===
                        true && (
                        <gynecologicHistoryForm.Field
                          name="painDuringSexDetails"
                          children={(field) => (
                            <FormControl className="relative">
                              <Input
                                placeholder="Other details"
                                value={field.state.value as string}
                                onChange={(evt) => {
                                  field.handleChange(evt.target.value);
                                }}
                                onBlur={(evt) => {
                                  if (field.state.meta.isDirty) {
                                    void gynecologicHistoryForm.handleSubmit();
                                  }
                                }}
                                disabled={readonly}
                              />
                            </FormControl>
                          )}
                        />
                      )}
                    </>
                  )}
                  <gynecologicHistoryForm.Field
                    name="hasHadPapTest"
                    children={(field) => (
                      <FormControl className="relative">
                        <Label className="mb-2">
                          Have you had any Pap Smears?
                        </Label>
                        <RadioGroup
                          className="gap-4"
                          onValueChange={(
                            value: 'yes' | 'never-had' | 'unsure'
                          ) => {
                            field.handleChange(value);
                            void gynecologicHistoryForm.handleSubmit();
                          }}
                          value={field.state.value ?? undefined}
                        >
                          <div className="flex items-center gap-2">
                            <RadioItem
                              id={`${field.name}-yes`}
                              value="yes"
                              disabled={readonly}
                            >
                              <RadioIndicator />
                            </RadioItem>
                            <Label
                              htmlFor={`${field.name}-yes`}
                              className="m-0 font-normal text-aster-secondary cursor-pointer"
                            >
                              Yes
                            </Label>
                          </div>
                          <div className="flex items-center gap-2">
                            <RadioItem
                              id={`${field.name}-never-had`}
                              value="never-had"
                              disabled={readonly}
                            >
                              <RadioIndicator />
                            </RadioItem>
                            <Label
                              htmlFor={`${field.name}-never-had`}
                              className="m-0 font-normal text-aster-secondary cursor-pointer"
                            >
                              Never Had
                            </Label>
                          </div>
                          <div className="flex items-center gap-2">
                            <RadioItem
                              id={`${field.name}-unsure`}
                              value="unsure"
                              disabled={readonly}
                            >
                              <RadioIndicator />
                            </RadioItem>
                            <Label
                              htmlFor={`${field.name}-unsure`}
                              className="m-0 font-normal text-aster-secondary cursor-pointer"
                            >
                              Unsure
                            </Label>
                          </div>
                        </RadioGroup>
                      </FormControl>
                    )}
                  />
                  {gynecologicHistoryForm.state.values.hasHadPapTest ===
                    'yes' && (
                    <>
                      <gynecologicHistoryForm.Field
                        name="lastPapTestDate"
                        validators={{
                          onBlur: ({ value }) => {
                            if (!value) return null;
                            if (value.length > 1 && !dayjs(value).isValid()) {
                              return 'Invalid date';
                            }
                            return null;
                          },
                        }}
                        children={(field) => (
                          <FormControl className="relative">
                            <Label>Last Pap Date</Label>
                            <InputMask
                              component={Input}
                              name={field.name}
                              mask="MM/DD/YYYY"
                              separate
                              showMask
                              replacement={{ D: /\d/, M: /\d/, Y: /\d/ }}
                              value={field.state.value as string}
                              onChange={(event) => {
                                const value =
                                  event.target.value === 'MM/DD/YYYY'
                                    ? ''
                                    : event.target.value;
                                field.handleChange(value ?? undefined);
                              }}
                              onBlur={(evt) => {
                                field.handleBlur();
                                if (
                                  dayjs(evt.target.value).isValid() &&
                                  field.state.meta.isDirty
                                ) {
                                  void gynecologicHistoryForm.handleSubmit();
                                }
                              }}
                              placeholder="MM/DD/YYYY"
                            />
                          </FormControl>
                        )}
                      />
                      <gynecologicHistoryForm.Field
                        name="lastPapTestResult"
                        children={(field) => (
                          <FormControl className="relative">
                            <Label>Result</Label>
                            <Input
                              value={field.state.value as string}
                              onChange={(evt) => {
                                field.handleChange(evt.target.value);
                              }}
                              onBlur={(evt) => {
                                if (field.state.meta.isDirty) {
                                  void gynecologicHistoryForm.handleSubmit();
                                }
                              }}
                              disabled={readonly}
                            />
                          </FormControl>
                        )}
                      />
                      <gynecologicHistoryForm.Field
                        name="hasHistoryOfAbnormalPap"
                        children={(field) => (
                          <FormControl className="relative">
                            <Label className="mb-2">
                              Do you have a history of any abnormal Pap Smears?
                            </Label>
                            <RadioGroup
                              className="gap-4"
                              onValueChange={(value) => {
                                field.handleChange(value as 'yes' | 'no');
                                void gynecologicHistoryForm.handleSubmit();
                              }}
                              value={field.state.value ?? undefined}
                            >
                              <RadioItem
                                id={`${field.name}-yes`}
                                value="yes"
                                disabled={readonly}
                              >
                                <RadioIndicator />
                              </RadioItem>
                              <Label
                                htmlFor={`${field.name}-yes`}
                                className="m-0 font-normal text-aster-secondary cursor-pointer"
                              >
                                Yes
                              </Label>
                              <RadioItem
                                id={`${field.name}-no`}
                                value="no"
                                disabled={readonly}
                              >
                                <RadioIndicator />
                              </RadioItem>
                              <Label
                                htmlFor={`${field.name}-no`}
                                className="m-0 font-normal text-aster-secondary cursor-pointer"
                              >
                                No
                              </Label>
                            </RadioGroup>
                          </FormControl>
                        )}
                      />
                      {gynecologicHistoryForm.state.values
                        .hasHistoryOfAbnormalPap === 'yes' && (
                        <gynecologicHistoryForm.Field
                          name="papAbnormalityHistoryAndTreatment"
                          children={(field) => (
                            <FormControl className="relative">
                              <Label className="mb-2">
                                Do you have a history of, and/or any treatment
                                of previously abnormal pap smears?
                              </Label>
                              <Input
                                value={field.state.value as string}
                                onChange={(evt) => {
                                  field.handleChange(evt.target.value);
                                }}
                                onBlur={(evt) => {
                                  if (field.state.meta.isDirty) {
                                    void gynecologicHistoryForm.handleSubmit();
                                  }
                                }}
                                disabled={readonly}
                              />
                            </FormControl>
                          )}
                        />
                      )}
                    </>
                  )}
                  <gynecologicHistoryForm.Field
                    name="hasHadStiScreening"
                    children={(field) => (
                      <FormControl className="relative">
                        <Label className="mb-2">
                          Have you had any STI Screenings?
                        </Label>
                        <RadioGroup
                          className="gap-4"
                          onValueChange={(value) => {
                            field.handleChange(
                              value as 'yes' | 'never-had' | 'unsure'
                            );
                            void gynecologicHistoryForm.handleSubmit();
                          }}
                          value={field.state.value ?? undefined}
                        >
                          <div className="flex items-center gap-2">
                            <RadioItem
                              id={`${field.name}-yes`}
                              value="yes"
                              disabled={readonly}
                            >
                              <RadioIndicator />
                            </RadioItem>
                            <Label
                              htmlFor={`${field.name}-yes`}
                              className="m-0 font-normal text-aster-secondary cursor-pointer"
                            >
                              Yes
                            </Label>
                          </div>
                          <div className="flex items-center gap-2">
                            <RadioItem
                              id={`${field.name}-never-had`}
                              value="never-had"
                              disabled={readonly}
                            >
                              <RadioIndicator />
                            </RadioItem>
                            <Label
                              htmlFor={`${field.name}-never-had`}
                              className="m-0 font-normal text-aster-secondary cursor-pointer"
                            >
                              Never Had
                            </Label>
                          </div>
                          <div className="flex items-center gap-2">
                            <RadioItem
                              id={`${field.name}-unsure`}
                              value="unsure"
                              disabled={readonly}
                            >
                              <RadioIndicator />
                            </RadioItem>
                            <Label
                              htmlFor={`${field.name}-unsure`}
                              className="m-0 font-normal text-aster-secondary cursor-pointer"
                            >
                              Unsure
                            </Label>
                          </div>
                        </RadioGroup>
                      </FormControl>
                    )}
                  />
                  {gynecologicHistoryForm.state.values.hasHadStiScreening ===
                    'yes' && (
                    <>
                      <gynecologicHistoryForm.Field
                        name="lastSTIScreeningDate"
                        validators={{
                          onBlur: ({ value }) => {
                            if (!value) return null;
                            if (value.length > 1 && !dayjs(value).isValid()) {
                              return 'Invalid date';
                            }
                            return null;
                          },
                        }}
                        children={(field) => (
                          <FormControl className="relative">
                            <Label className="mb-2">
                              Last STI Screening Date
                            </Label>
                            <InputMask
                              component={Input}
                              name={field.name}
                              mask="MM/DD/YYYY"
                              separate
                              showMask
                              replacement={{ D: /\d/, M: /\d/, Y: /\d/ }}
                              value={field.state.value as string}
                              onChange={(event) => {
                                const value =
                                  event.target.value === 'MM/DD/YYYY'
                                    ? ''
                                    : event.target.value;
                                field.handleChange(value ?? undefined);
                              }}
                              onBlur={(evt) => {
                                field.handleBlur();
                                if (field.state.meta.isDirty) {
                                  void gynecologicHistoryForm.handleSubmit();
                                }
                              }}
                              placeholder="MM/DD/YYYY"
                            />
                          </FormControl>
                        )}
                      />
                      <gynecologicHistoryForm.Field
                        name="previousInfectionDiseasesOrSTI"
                        children={(field) => (
                          <FormControl className="relative">
                            <Label className="mb-2">
                              Do you have any history of infectious diseases or
                              STI?
                            </Label>
                            <div className="flex flex-col flex-wrap gap-2 max-h-[130px]">
                              {defaultPreviousInfectionsDiseasesOrSTI.map(
                                (problem) => (
                                  <div
                                    key={problem.value}
                                    className="flex gap-2"
                                  >
                                    <Checkbox
                                      id={`${field.name}-${problem.value}`}
                                      key={problem.value}
                                      checked={
                                        field.state.value?.find(
                                          (item: MultipleChoiceQuestion) =>
                                            item.value === problem.value
                                        )?.checked
                                      }
                                      onCheckedChange={(value) => {
                                        if (value === 'indeterminate') return;
                                        const updatedItems =
                                          field.state.value?.map(
                                            (item: MultipleChoiceQuestion) =>
                                              item.value === problem.value
                                                ? { ...item, checked: value }
                                                : item
                                          );

                                        if (updatedItems) {
                                          field.handleChange(updatedItems);
                                          void gynecologicHistoryForm.handleSubmit();
                                        }
                                      }}
                                      disabled={readonly}
                                    />
                                    <Label
                                      className="font-normal text-aster-secondary cursor-pointer"
                                      htmlFor={`${field.name}-${problem.value}`}
                                    >
                                      {problem.text}
                                    </Label>
                                  </div>
                                )
                              )}
                            </div>
                          </FormControl>
                        )}
                      />
                      <gynecologicHistoryForm.Field
                        name="previousInfectionDiseasesOrSTIDetails"
                        children={(field) => (
                          <FormControl className="relative">
                            <Input
                              placeholder="Other details"
                              value={field.state.value as string}
                              onChange={(evt) => {
                                field.handleChange(evt.target.value);
                              }}
                              onBlur={(evt) => {
                                if (field.state.meta.isDirty) {
                                  void gynecologicHistoryForm.handleSubmit();
                                }
                              }}
                              disabled={readonly}
                            />
                          </FormControl>
                        )}
                      />
                      <gynecologicHistoryForm.Field
                        name="wasPartnerTreatedForSTI"
                        children={(field) => (
                          <FormControl className="relative">
                            <Label className="mb-2">Was partner treated?</Label>
                            <RadioGroup
                              className="gap-4"
                              onValueChange={(value) => {
                                field.handleChange(value as 'yes' | 'no');
                                void gynecologicHistoryForm.handleSubmit();
                              }}
                              value={field.state.value ?? undefined}
                            >
                              <div className="flex items-center gap-2">
                                <RadioItem
                                  id={`${field.name}-yes`}
                                  value="yes"
                                  disabled={readonly}
                                >
                                  <RadioIndicator />
                                </RadioItem>
                                <Label
                                  htmlFor={`${field.name}-yes`}
                                  className="m-0 font-normal text-aster-secondary cursor-pointer"
                                >
                                  Yes
                                </Label>
                              </div>
                              <div className="flex items-center gap-2">
                                <RadioItem
                                  id={`${field.name}-no`}
                                  value="no"
                                  disabled={readonly}
                                >
                                  <RadioIndicator />
                                </RadioItem>
                                <Label
                                  htmlFor={`${field.name}-no`}
                                  className="m-0 font-normal text-aster-secondary cursor-pointer"
                                >
                                  No
                                </Label>
                              </div>
                            </RadioGroup>
                          </FormControl>
                        )}
                      />
                      <gynecologicHistoryForm.Field
                        name="wasPartnerTreatedForSTIDetails"
                        children={(field) => (
                          <FormControl className="relative">
                            <Input
                              placeholder="Other details"
                              value={field.state.value as string}
                              onChange={(evt) => {
                                field.handleChange(evt.target.value);
                              }}
                              onBlur={(evt) => {
                                if (field.state.meta.isDirty) {
                                  void gynecologicHistoryForm.handleSubmit();
                                }
                              }}
                              disabled={readonly}
                            />
                          </FormControl>
                        )}
                      />
                    </>
                  )}
                  <gynecologicHistoryForm.Field
                    name="hasHadMammogram"
                    children={(field) => (
                      <FormControl className="relative">
                        <Label className="mb-2">
                          Have you had any Mammograms?
                        </Label>
                        <RadioGroup
                          className="gap-4"
                          onValueChange={(value) => {
                            field.handleChange(
                              value as 'yes' | 'never-had' | 'unsure'
                            );
                            void gynecologicHistoryForm.handleSubmit();
                          }}
                          value={field.state.value ?? undefined}
                        >
                          <div className="flex items-center gap-2">
                            <RadioItem
                              id={`${field.name}-yes`}
                              value="yes"
                              disabled={readonly}
                            >
                              <RadioIndicator />
                            </RadioItem>
                            <Label
                              htmlFor={`${field.name}-yes`}
                              className="m-0 font-normal text-aster-secondary cursor-pointer"
                            >
                              Yes
                            </Label>
                          </div>
                          <div className="flex items-center gap-2">
                            <RadioItem
                              id={`${field.name}-never-had`}
                              value="never-had"
                              disabled={readonly}
                            >
                              <RadioIndicator />
                            </RadioItem>
                            <Label
                              htmlFor={`${field.name}-never-had`}
                              className="m-0 font-normal text-aster-secondary cursor-pointer"
                            >
                              Never Had
                            </Label>
                          </div>
                          <div className="flex items-center gap-2">
                            <RadioItem
                              id={`${field.name}-unsure`}
                              value="unsure"
                              disabled={readonly}
                            >
                              <RadioIndicator />
                            </RadioItem>
                            <Label
                              htmlFor={`${field.name}-unsure`}
                              className="m-0 font-normal text-aster-secondary cursor-pointer"
                            >
                              Unsure
                            </Label>
                          </div>
                        </RadioGroup>
                      </FormControl>
                    )}
                  />
                  {gynecologicHistoryForm.state.values.hasHadMammogram ===
                    'yes' && (
                    <>
                      <gynecologicHistoryForm.Field
                        name="lastMammogramDate"
                        validators={{
                          onBlur: ({ value }) => {
                            if (!value) return null;
                            if (value.length > 1 && !dayjs(value).isValid()) {
                              return 'Invalid date';
                            }
                            return null;
                          },
                        }}
                        children={(field) => (
                          <FormControl className="relative">
                            <Label className="mb-2">Last Mammogram Date</Label>
                            <InputMask
                              component={Input}
                              name={field.name}
                              mask="MM/DD/YYYY"
                              separate
                              showMask
                              replacement={{ D: /\d/, M: /\d/, Y: /\d/ }}
                              value={field.state.value as string}
                              onChange={(event) =>
                                field.handleChange(event.target.value)
                              }
                              onBlur={(evt) => {
                                field.handleBlur();
                                if (
                                  dayjs(evt.target.value).isValid() &&
                                  field.state.meta.isDirty
                                ) {
                                  void gynecologicHistoryForm.handleSubmit();
                                }
                              }}
                              placeholder="MM/DD/YYYY"
                            />
                          </FormControl>
                        )}
                      />
                      <gynecologicHistoryForm.Field
                        name="hasHistoryOfAbnormalMammogram"
                        children={(field) => (
                          <FormControl className="relative">
                            <Label className="mb-2">
                              Do you have a history of any abnormal Mammograms?
                            </Label>
                            <RadioGroup
                              className="gap-4"
                              onValueChange={(value) => {
                                field.handleChange(value as 'yes' | 'no');
                                void gynecologicHistoryForm.handleSubmit();
                              }}
                              value={field.state.value ?? undefined}
                            >
                              <RadioItem
                                id={`${field.name}-yes`}
                                value="yes"
                                disabled={readonly}
                              >
                                <RadioIndicator />
                              </RadioItem>
                              <Label
                                htmlFor={`${field.name}-yes`}
                                className="m-0 font-normal text-aster-secondary cursor-pointer"
                              >
                                Yes
                              </Label>
                              <RadioItem
                                id={`${field.name}-no`}
                                value="no"
                                disabled={readonly}
                              >
                                <RadioIndicator />
                              </RadioItem>
                              <Label
                                htmlFor={`${field.name}-no`}
                                className="m-0 font-normal text-aster-secondary cursor-pointer"
                              >
                                No
                              </Label>
                            </RadioGroup>
                          </FormControl>
                        )}
                      />
                      {gynecologicHistoryForm.state.values
                        .hasHistoryOfAbnormalMammogram === 'yes' && (
                        <gynecologicHistoryForm.Field
                          name="mammogramAbnormalityHistoryAndTreatment"
                          children={(field) => (
                            <FormControl className="relative">
                              <Label className="mb-2">
                                Do you have any history of, and/or previous
                                treatment for abnormal mammograms?
                              </Label>
                              <Input
                                value={field.state.value as string}
                                onChange={(evt) => {
                                  field.handleChange(evt.target.value);
                                }}
                                onBlur={(evt) => {
                                  if (field.state.meta.isDirty) {
                                    void gynecologicHistoryForm.handleSubmit();
                                  }
                                }}
                                disabled={readonly}
                              />
                            </FormControl>
                          )}
                        />
                      )}
                    </>
                  )}
                </div>
              </div>
            </form>
          )}
        </div>
      </>
    );
  }
);

export default GynecologicHistory;
