import { useNavigate } from 'react-router';

import Typography from '../components/Typography';
import ButtonType from '../components/Button';

import AsterLogo from '../assets/aster';
import Illustration from '../assets/aster_baggu_illustration_black.webp';

type ErrorProps = {
  type: 404 | 500;
};

type ErrorCopy = {
  headline: string | React.ReactNode;
  paragraphs: (string | React.ReactNode)[];
  allowRefresh?: boolean;
};

const errorCopySet: Record<ErrorProps['type'], ErrorCopy> = {
  404: {
    headline: <>Oops! We can’t find that page.</>,
    paragraphs: [<>Sorry about that. Try checking that the URL is correct.</>],
  },
  500: {
    headline: <>Oops! Something went wrong</>,
    paragraphs: [
      <>Sorry about that! Try refreshing the page.</>,
      <>
        If the problem persists,{' '}
        <a href="mailto:support@astercare.com" className="underline">
          email support
        </a>{' '}
        and we’ll get right on it.
      </>,
      <>
        You can also try forcing the app to refresh by clicking the button
        below.
      </>,
    ],
    allowRefresh: true,
  },
};

const Error = ({ type }: ErrorProps) => {
  const copy = errorCopySet[type];

  const navigate = useNavigate();

  // NOTE: Since `5xx` exceptions often cause the site to crash altogether,
  // performing a full navigation and triggering a page reload from scratch
  // scratch could help re-attempt the issue.
  //
  // See `src/Router.tsx:23`.
  const goBackHome = async () => {
    if (type === 500) {
      return (window.location.href = '/');
    }

    await navigate('/', { replace: true });
  };

  const refresh = () =>
    fetch(window.location.href, {
      headers: {
        Pragma: 'no-cache',
        Expires: '-1',
        'Cache-Control': 'no-cache',
      },
    }).then(() => window.location.reload());

  return (
    <div className="top-0 left-0 z-50 absolute w-screen h-screen">
      <div className="relative bg-grayBackground p-32 w-screen h-screen overflow-hidden text-black">
        <img
          src={Illustration}
          className="top-0 left-[calc(50%-512px)] lg:left-0 z-0 absolute opacity-[0.1] lg:w-full min-w-[1024px] h-auto pointer-events-none user-select-none"
          alt="Aster Baggu Illustration Black"
        />
        <div className="z-20 relative mx-auto mb-10 px-9 py-3 w-fit">
          <AsterLogo />
        </div>
        <div className="z-10 relative bg-white mx-auto p-8 rounded-[20px] w-fit text-center">
          <div className="mb-6">
            <Typography
              variant="h1"
              customClass="text-2xl text-aster-main mb-6"
            >
              {copy.headline}
            </Typography>
            {copy.paragraphs.map((paragraph, i) => (
              <Typography
                key={i}
                variant="bodyMedium"
                customClass="text-gray-500 mb-6"
              >
                {paragraph}
              </Typography>
            ))}
          </div>
          <div className="flex justify-center gap-x-4">
            {copy.allowRefresh && (
              <ButtonType
                variant="outlined"
                text="Force Refresh"
                onClick={refresh}
              />
            )}
            <ButtonType
              variant="contained"
              text="Return to Aster"
              onClick={goBackHome}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Error;
