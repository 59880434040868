import { useForm } from '@tanstack/react-form';
import {
  PatientInfoDTO,
  PatientInsurance,
} from '@aster/app/core/shared/dtos/patient';

export const useInsuranceInfoForm = ({
  defaultValues,
  onSubmit,
}: {
  defaultValues: PatientInfoDTO | undefined;
  onSubmit: (value: {
    insurances: Partial<PatientInsurance>[];
    cashPayOnly: boolean;
  }) => void;
}) => {
  const { insurances } = defaultValues ?? {};

  // Prepare default insurances, ensuring there's at least one empty record
  const defaultInsurances = insurances?.length
    ? insurances.map((insurance) => ({
        insuranceProvider: insurance?.insuranceProvider ?? '',
        insuranceNumber: insurance?.insuranceNumber ?? '',
        insuranceGroupId: insurance?.insuranceGroupId ?? '',
        useForBaby: insurance?.useForBaby ?? false,
      }))
    : [
        {
          insuranceProvider: '',
          insuranceNumber: '',
          insuranceGroupId: '',
          useForBaby: false,
        },
      ];

  const insuranceInfoForm = useForm({
    defaultValues: {
      insurances: defaultInsurances,
      cashOnly: defaultValues?.cashPayOnly ?? false,
    },
    onSubmit: async ({ value }) => {
      return onSubmit({
        insurances: value.insurances,
        cashPayOnly: value.cashOnly,
      });
    },
  });
  return insuranceInfoForm;
};
