import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from '../../../app/axiosConfig';
import {
  createMutationScopeID,
  MutationScope,
} from '../../../mutations/utils/create-mutation-scope-id';
import { PatientInfoDTO } from '@aster/app/core/shared/dtos/patient';

export function useUpdatePatientByTokenMutation(
  token?: string,
  onSuccessCb?: (() => void) | null,
  onErrorCb?: (() => void) | null
) {
  const queryClient = useQueryClient();

  const updatePatientByToken = async (newForm: Partial<PatientInfoDTO>) =>
    axios.put(`/v2/i/patients/by-token/${token}`, newForm);

  const updatePatientMutation = useMutation({
    mutationKey: ['updatePatient'],
    mutationFn: updatePatientByToken,
    scope: {
      id: createMutationScopeID(MutationScope.PATIENT),
    },
    onMutate: (newForm: any) => {
      const oldForm = queryClient.getQueryData([
        'guest-patient-info',
        token as string,
      ]);

      queryClient.setQueryData(
        ['guest-patient-info', token],
        (oldForm: Partial<PatientInfoDTO>) => ({
          ...oldForm,
          ...newForm,
        })
      );

      return { previousFormInfo: oldForm };
    },
    onSuccess: () => {
      onSuccessCb && onSuccessCb();
    },
    onError: (error, _, context) => {
      queryClient.setQueryData(
        ['guest-patient-info', token],
        context?.previousFormInfo
      );
      onErrorCb && onErrorCb();
    },
  });

  return { updatePatientMutation };
}
