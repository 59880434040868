import { useForm } from '@tanstack/react-form';
import { ContactInfo } from '../types/patient-profile';
import { PatientInfoDTO } from '@aster/app/core/shared/dtos/patient';

export type ContactInfoFields = Pick<
  PatientInfoDTO,
  | 'phoneNumber'
  | 'email'
  | 'streetAddress1'
  | 'streetAddress2'
  | 'city'
  | 'state'
  | 'zipCode'
>;

export const useContactInfoForm = ({
  defaultValues,
  onSubmit,
}: {
  defaultValues: PatientInfoDTO | undefined;
  onSubmit: (value: Partial<ContactInfoFields>) => void;
}) => {
  const contactInfoForm = useForm({
    defaultValues: {
      phoneNumber: defaultValues?.phoneNumber ?? undefined,
      email: defaultValues?.email ?? undefined,
      streetAddress1: defaultValues?.streetAddress1 ?? undefined,
      streetAddress2: defaultValues?.streetAddress2 ?? undefined,
      city: defaultValues?.city ?? undefined,
      state: defaultValues?.state ?? undefined,
      zipCode: defaultValues?.zipCode ?? undefined,
    } as ContactInfo,
    onSubmit: async ({ value }) => {
      const email = value.email ?? undefined;
      const phoneNumber = value.phoneNumber ?? undefined;

      onSubmit({ ...value, email, phoneNumber });
    },
  });

  return contactInfoForm;
};
