import { cn } from '@aster/client/utils';
import * as React from 'react';

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  startAdornment?: JSX.Element;
  endAdornment?: JSX.Element;
  hasError?: boolean;
  errorMessageId?: string;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      className,
      type,
      startAdornment,
      endAdornment,
      errorMessageId,
      hasError,
      ...props
    },
    ref
  ) => {
    return (
      <div
        className={cn(
          'peer flex items-center justify-center gap-2 px-3 h-10 rounded-md border border-gray-300 bg-white ring-offset-background',
          'hover:border-gray-600',
          'focus-within:outline-none focus-within:border-gray-900 focus-within:shadow-elevation1',
          'data-[disabled=true]:cursor-not-allowed data-[disabled=true]:bg-gray-200 data-[disabled=true]:hover:border-gray-300',
          {
            'border-red-600': hasError,
          },
          className
        )}
        data-disabled={props.disabled}
      >
        {startAdornment && (
          <div
            className={cn('text-muted-foreground')}
            data-testid="start-adornment"
          >
            {startAdornment}
          </div>
        )}
        <input
          type={type}
          aria-invalid={hasError}
          aria-errormessage={errorMessageId}
          className={cn(
            'flex h-full w-full rounded-md bg-transparent py-2 text-base sm:text-sm file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground',
            'shadow-none outline-none border-none',
            'focus:outline-none focus:border-none focus:shadow-none',
            '[&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none'
          )}
          ref={ref}
          {...props}
        />
        {endAdornment && (
          <div
            className={cn('text-muted-foreground')}
            data-testid="end-adornment"
          >
            {endAdornment}
          </div>
        )}
      </div>
    );
  }
);
Input.displayName = 'Input';

export { Input };
