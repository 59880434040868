import Typography from '../../../components/Typography';
import { CircularProgress } from '@mui/material';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@aster/client/ui/Select/Select';
import { FormControl } from '@aster/client/ui/FormControl/FormControl';
import { Label } from '@aster/client/ui/Label/Label';
import { Input } from '@aster/client/ui/Input/Input';
import { isPatientReadonly } from '../utils/is-patient-readonly';
import { useDebouncedCallback } from 'use-debounce';
import {
  RelationshipInfoFields,
  useReleationshipInfoForm,
} from '../hooks/useRelationshipInfoForms';
import { Profile, useAuth } from '../../../authentication/AuthProvider';
import { useUserUsagePreferences } from '@aster/shared/shared/client/utils';
import dayjs from 'dayjs';
import { LAST_SAVED_RELATIONSHIP_INFORMATION } from '../constants';
import { ProfileSaveButton } from '../profileTabs/components/ProfileSaveButton';
import { forwardRef, useImperativeHandle, useState } from 'react';
import timeSinceLastSaved from '../utils/timeSinceLastSaved';
import { PatientInfoDTO } from '@aster/app/core/shared/dtos/patient';
import { InputMask } from '@react-input/mask';
import { useProfileTabStore } from '../profileTabs/ProfileTab.store';
import { useShallow } from 'zustand/react/shallow';
import { useStore } from '@tanstack/react-form';

const Relationship = forwardRef(
  (
    {
      updatePatientMutation,
      patientInfo,
      isPatientLoading,
    }: {
      patientInfo: PatientInfoDTO | undefined;
      updatePatientMutation: any;
      isPatientLoading: boolean;
    },
    ref
  ) => {
    const { setFormUnsavedStatus } = useProfileTabStore(
      useShallow((state) => ({
        setFormUnsavedStatus: state.setFormUnsavedStatus,
      }))
    );
    const [debounceValue, setDebounceValue] = useState<number>(2000);
    useImperativeHandle(ref, () => ({
      submit: () => {
        setDebounceValue(0);
        void relationshipInfoForm.handleSubmit();
      },
    }));

    const { profile } = useAuth();
    const { storePreference, readPreference } = useUserUsagePreferences(
      profile as Profile
    );

    const lastSaved = readPreference<string>(
      LAST_SAVED_RELATIONSHIP_INFORMATION
    );
    const lastSavedString = lastSaved ? timeSinceLastSaved(lastSaved) : '';

    const relationshipInfoForm = useReleationshipInfoForm({
      defaultValues: patientInfo as PatientInfoDTO,
      onSubmit: (value) => {
        return save(value);
      },
    });

    useStore(relationshipInfoForm.store, ({ isDirty }) => {
      setFormUnsavedStatus('relationship', isDirty);
    });

    const save = useDebouncedCallback(
      async (value: Partial<RelationshipInfoFields>) => {
        await updatePatientMutation.mutateAsync(value);
        storePreference(
          LAST_SAVED_RELATIONSHIP_INFORMATION,
          dayjs().toISOString()
        );
        setDebounceValue(2000);
        relationshipInfoForm.reset(relationshipInfoForm.state.values, {
          keepDefaultValues: true,
        });
      },
      debounceValue
    );

    const readonly = isPatientReadonly(patientInfo);

    const showPartnerName =
      relationshipInfoForm.state.values.relationshipStatus &&
      !['single', 'prefer_not_to_say'].includes(
        relationshipInfoForm.state.values.relationshipStatus
      );

    return (
      <>
        <ProfileSaveButton
          lastSavedString={lastSavedString}
          updatePatientMutation={updatePatientMutation}
          form={relationshipInfoForm}
        />
        <div className="pb-20 w-full container">
          {isPatientLoading ? (
            <CircularProgress />
          ) : (
            <form
              onSubmit={(evt) => {
                evt.preventDefault();
                evt.stopPropagation();
                void relationshipInfoForm.handleSubmit();
              }}
            >
              <div className="flex flex-wrap gap-10 xl:gap-40 h-fit">
                <div className="flex flex-col gap-y-5 w-full sm:w-auto">
                  <Typography
                    text="Relationship"
                    variant="h5"
                    customClass="font-semibold"
                  ></Typography>
                  <relationshipInfoForm.Field
                    name="relationshipStatus"
                    children={(field) => (
                      <FormControl>
                        <Label>Relationship Status</Label>
                        <Select
                          value={field.state.value as string}
                          defaultValue={field.state.value as string}
                          onValueChange={(value) => {
                            if (
                              ['single', 'prefer_not_to_say'].includes(value)
                            ) {
                              relationshipInfoForm.state.values.partnerName =
                                '';
                            }
                            field.handleChange(value);
                            void relationshipInfoForm.handleSubmit();
                          }}
                          disabled={readonly}
                        >
                          <SelectTrigger>
                            <SelectValue placeholder={'Select an option'} />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectGroup>
                              <SelectItem value="single">Single</SelectItem>
                              <SelectItem value="married">Married</SelectItem>
                              <SelectItem value="divorced">Divorced</SelectItem>
                              <SelectItem value="widowed">Widowed</SelectItem>
                              <SelectItem value="partnered">
                                Partnered
                              </SelectItem>
                              <SelectItem value="other">Other</SelectItem>
                              <SelectItem value="prefer_not_to_say">
                                Prefer Not to Say
                              </SelectItem>
                            </SelectGroup>
                          </SelectContent>
                        </Select>
                      </FormControl>
                    )}
                  />
                  {showPartnerName && (
                    <>
                      <relationshipInfoForm.Field
                        name="partnerName"
                        validators={{
                          onChangeListenTo: ['relationshipStatus'],
                          onChange: ({ fieldApi }) => {
                            if (
                              fieldApi.form.getFieldValue(
                                'relationshipStatus'
                              ) === 'single' &&
                              fieldApi.form.getFieldValue('partnerName') !== ''
                            ) {
                              fieldApi.setValue('');
                            }
                            return undefined;
                          },
                        }}
                        children={(field) => (
                          <FormControl>
                            <Label>Partner's Name</Label>
                            <Input
                              placeholder="Name"
                              id="partnerName"
                              name="partnerName"
                              value={field.state.value as string}
                              onChange={(evt) =>
                                field.handleChange(evt.target.value)
                              }
                              onBlur={(evt) => {
                                if (field.state.meta.isDirty) {
                                  void relationshipInfoForm.handleSubmit();
                                }
                              }}
                              disabled={readonly}
                            />
                          </FormControl>
                        )}
                      />
                      <relationshipInfoForm.Field
                        name="partnerRace"
                        children={(field) => (
                          <FormControl>
                            <Label>Partner's Race</Label>
                            <Select
                              value={field.state.value as string}
                              onValueChange={(value) => {
                                if (value === '') return;
                                field.handleChange(value);
                                if (field.state.meta.isDirty) {
                                  void relationshipInfoForm.handleSubmit();
                                }
                              }}
                              disabled={readonly}
                            >
                              <SelectTrigger>
                                <SelectValue placeholder="Select an option" />
                              </SelectTrigger>
                              <SelectContent>
                                <SelectGroup>
                                  <SelectItem value="black">
                                    Black or African American
                                  </SelectItem>
                                  <SelectItem value="white">White</SelectItem>
                                  <SelectItem value="eastasian">
                                    East and Southeast Asian
                                  </SelectItem>
                                  <SelectItem value="southasian">
                                    South and Central Asian
                                  </SelectItem>
                                  <SelectItem value="native">
                                    Native American or Alaska Native
                                  </SelectItem>
                                  <SelectItem value="hawaiian">
                                    Native Hawaiian or Pacific Islander
                                  </SelectItem>
                                  <SelectItem value="middleeastern-northafrican">
                                    Middle Eastern/North African
                                  </SelectItem>
                                  <SelectItem value="other">Other</SelectItem>
                                  <SelectItem value="notsay">
                                    Prefer not to say
                                  </SelectItem>
                                </SelectGroup>
                              </SelectContent>
                            </Select>
                          </FormControl>
                        )}
                      />
                      <relationshipInfoForm.Field
                        name="partnerEthnicity"
                        children={(field) => (
                          <FormControl>
                            <Label>Partner's Ethnicity</Label>
                            <Select
                              value={field.state.value as string}
                              onValueChange={(value) => {
                                if (value === '') return;
                                field.handleChange(value);
                                if (field.state.meta.isDirty) {
                                  void relationshipInfoForm.handleSubmit();
                                }
                              }}
                              disabled={readonly}
                            >
                              <SelectTrigger>
                                <SelectValue placeholder="Select an option" />
                              </SelectTrigger>
                              <SelectContent>
                                <SelectGroup>
                                  <SelectItem value="hispanic">
                                    Hispanic or Latino
                                  </SelectItem>
                                  <SelectItem value="nohispanic">
                                    Not Hispanic or Latino
                                  </SelectItem>
                                  <SelectItem value="notsay">
                                    Prefer not to say
                                  </SelectItem>
                                  <SelectItem value="other">Other</SelectItem>
                                </SelectGroup>
                              </SelectContent>
                            </Select>
                          </FormControl>
                        )}
                      />
                      <relationshipInfoForm.Field
                        name="partnerPreferredLanguage"
                        children={(field) => (
                          <FormControl>
                            <Label>Partner's Preferred Language</Label>
                            <Input
                              placeholder="Language"
                              id="partnerPreferredLanguage"
                              name="partnerPreferredLanguage"
                              value={field.state.value as string}
                              onChange={(evt) =>
                                field.handleChange(evt.target.value)
                              }
                              onBlur={(evt) => {
                                if (field.state.meta.isDirty) {
                                  void relationshipInfoForm.handleSubmit();
                                }
                              }}
                              disabled={readonly}
                            />
                          </FormControl>
                        )}
                      />
                      <relationshipInfoForm.Field
                        name="partnerOccupation"
                        children={(field) => (
                          <FormControl>
                            <Label>Partner's Occupation</Label>
                            <Input
                              placeholder="Occupation"
                              id="partnerOccupation"
                              name="partnerOccupation"
                              value={field.state.value as string}
                              onChange={(evt) =>
                                field.handleChange(evt.target.value)
                              }
                              onBlur={(evt) => {
                                if (field.state.meta.isDirty) {
                                  void relationshipInfoForm.handleSubmit();
                                }
                              }}
                              disabled={readonly}
                            />
                          </FormControl>
                        )}
                      />
                      <relationshipInfoForm.Field
                        name="partnerEducation"
                        children={(field) => (
                          <FormControl>
                            <Label>Partner's Education</Label>
                            <Select
                              value={field.state.value}
                              onValueChange={(value) => {
                                if (value === '') return;
                                field.handleChange(
                                  value as
                                    | 'high-school'
                                    | 'bachelor-degree'
                                    | 'master-degree'
                                    | 'doctorate-degree'
                                );
                                if (field.state.meta.isDirty) {
                                  void relationshipInfoForm.handleSubmit();
                                }
                              }}
                              disabled={readonly}
                            >
                              <SelectTrigger>
                                <SelectValue placeholder="Select an option" />
                              </SelectTrigger>
                              <SelectContent>
                                <SelectGroup>
                                  <SelectItem value="high-school">
                                    High School
                                  </SelectItem>
                                  <SelectItem value="bachelor-degree">
                                    Bachelor's Degree
                                  </SelectItem>
                                  <SelectItem value="master-degree">
                                    Master's Degree
                                  </SelectItem>
                                  <SelectItem value="doctorate-degree">
                                    Doctorate or professional degree
                                  </SelectItem>
                                </SelectGroup>
                              </SelectContent>
                            </Select>
                          </FormControl>
                        )}
                      />
                      <relationshipInfoForm.Field
                        name="partnerPhone"
                        children={(field) => (
                          <FormControl>
                            <Label>Partner's Phone</Label>
                            <Input
                              id="phoneNumber"
                              placeholder="(xxx) xxx-xxxx"
                              name="phoneNumber"
                              value={field.state.value as string}
                              onChange={(evt) =>
                                field.handleChange(evt.target.value)
                              }
                              onBlur={(evt) => {
                                if (field.state.meta.isDirty) {
                                  void relationshipInfoForm.handleSubmit();
                                }
                              }}
                              disabled={readonly}
                            />
                          </FormControl>
                        )}
                      />
                      <relationshipInfoForm.Field
                        name="partnerSocialSecurityNumber"
                        children={(field) => (
                          <FormControl>
                            <Label>Social Security Number</Label>
                            <InputMask
                              className="bg-white"
                              component={Input}
                              name={field.name}
                              mask="XXX-XX-XXXX"
                              placeholder="XXX-XX-XXXX"
                              separate
                              showMask
                              replacement={{ X: /\d/ }}
                              value={field.state.value as string}
                              onChange={(evt) => {
                                field.handleChange(evt.target.value);
                              }}
                              onBlur={() => {
                                if (field.state.meta.isDirty) {
                                  void relationshipInfoForm.handleSubmit();
                                }
                              }}
                              disabled={readonly}
                              errorMessageId={`err-${field.name}`}
                            />
                          </FormControl>
                        )}
                      />
                    </>
                  )}
                </div>
              </div>
            </form>
          )}
        </div>
      </>
    );
  }
);

export default Relationship;
